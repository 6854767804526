<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="loading"
  >
    <div slot="actions">
      <div class="field has-addons">
        <div class="is-flex mr-2">
          <b-button
            v-if="$device.mobile"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon icon="magnify" size="is-small" />
          </b-button>
          <b-input
            ref="search"
            v-model="headers[5].input"
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            :placeholder="placeholders.user.email"
            icon="magnify"
            rounded
            @input="getUsers('global-search')"
            @blur="hideMobile()"
          />
        </div>
        <b-dropdown
          ref="dropdown"
          aria-role="list"
          position="is-bottom-left"
          :close-on-click="false"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              :size="$device.mobile ? 'is-small' : ''"
              type="is-default"
              rounded
            />
          </div>

          <b-dropdown-item
            aria-role="listitem"
            @click="
              () => {
                openDrawer('openUserForm', {
                  index: -1,
                  level: 'main',
                  countries: countries,
                  sites: sites,
                });
                closeDrop();
              }
            "
          >
            <b-icon icon="plus" size="is-small" />
            {{ $t("create") }}
          </b-dropdown-item>

          <b-dropdown-item separator />

          <b-dropdown-item
            aria-role="listitem"
            class="pr-3"
            @click="showColumnsTogle = !showColumnsTogle"
          >
            <b-icon icon="view-column" size="is-small" />
            {{ $tc("column", 2) }}
            <b-icon
              class="is-pulled-right"
              :icon="!showColumnsTogle ? 'chevron-down' : 'chevron-up'"
              size="is-small"
            />
          </b-dropdown-item>
          <template v-if="showColumnsTogle">
            <template v-for="item in visibleCols">
              <b-dropdown-item
                v-if="item.key != 'actions'"
                :key="item.key"
                aria-role="listitem"
                :disabled="item.disabled"
                :focusable="false"
                custom
              >
                <b-switch
                  v-model="item.display"
                  :disabled="item.disabled"
                  type="is-success"
                >
                  {{ item.label }}
                </b-switch>
              </b-dropdown-item>
            </template>
          </template>
        </b-dropdown>
      </div>
    </div>
    <Card content-class="c-table">
      <b-table
        :data="list"
        :loading="loading"
        hoverable
        mobile-cards
        striped
        backend-sorting
        sort-multiple
        :sort-multiple-data="sortingPriority"
        sort-multiple-key="shiftKey"
        :total="total"
        @sort="sortPressed"
        @sorting-priority-removed="sortingPriorityRemoved"
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            :field="head.value"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            :searchable="head.searchable"
            :numeric="head.numeric"
            :sticky="false"
            v-bind="head"
            :visible="visibleCols[head.value].display"
            header-class="sticky-header"
          >
            <template slot="searchable">
              <template
                v-if="head.value == 'incoterm' || head.value == 'factoring'"
              >
                <b-select-validation
                  v-model="head.input"
                  size="is-small"
                  rounded
                  hide-details
                  @input="getUsers"
                >
                  <template>
                    <option
                      v-for="opt in head.filter_values"
                      :key="opt"
                      :value="opt"
                    >
                      {{ opt }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.value == 'site_id'">
                <b-select-validation
                  v-model="head.input"
                  size="is-small"
                  rounded
                  hide-details
                  @input="getUsers"
                >
                  <template>
                    <option v-for="opt in sites" :key="opt.id" :value="opt.id">
                      {{ opt.label }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.value == 'tld_id'">
                <b-select-validation
                  v-model="head.input"
                  size="is-small"
                  rounded
                  hide-details
                  @input="getUsers"
                >
                  <template>
                    <option v-for="opt in tlds" :key="opt.id" :value="opt.id">
                      {{ opt.country.label }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.value == 'company_type'">
                <b-select-validation
                  v-model="head.input"
                  size="is-small"
                  rounded
                  hide-details
                  @input="getUsers"
                >
                  <template>
                    <option v-for="opt in types" :key="opt.id" :value="opt.id">
                      {{ opt.label }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.value == 'status'">
                <b-select-validation
                  v-model="head.input"
                  size="is-small"
                  rounded
                  hide-details
                  @input="getUsers"
                >
                  <template>
                    <option
                      v-for="opt in status"
                      :key="opt.key"
                      :value="opt.key"
                    >
                      {{ opt.label }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.value == 'salesman'">
                <b-select-validation
                  v-model="head.input"
                  size="is-small"
                  rounded
                  hide-details
                  @input="getUsers"
                >
                  <template>
                    <option
                      v-for="opt in salesmen"
                      :key="opt.id"
                      :value="opt.id"
                    >
                      {{ opt.fname + " " + opt.lname }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.filter_type == 'autocomplete'">
                <b-autocomplete-validation
                  v-model="head.input"
                  expanded
                  fit-content
                  class="has-text-weight-normal"
                  width="100%"
                  rounded
                  size="is-small"
                  :data="head.filter_values"
                  :placeholder="placeholders.user.phone.country"
                  :item-text="head.filter_text"
                  :field="head.filter_id"
                  @change="sendEndpointUsers()"
                />
              </template>
              <template v-else-if="head.value == 'last_order'">
                <b-datepicker
                  v-model="head.input"
                  rounded
                  :placeholder="placeholders.date.day"
                  size="is-small"
                  editable
                  :min-date="new Date('01/01/2013')"
                  :max-date="new Date()"
                  @input="getUsers()"
                />
                <span class="custom-date-filter" @click="showDrop(head.value)">
                  <b-icon icon="filter" size="is-small" />
                </span>
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'is')"
                  >
                    {{ $t("is") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'greater')"
                  >
                    {{ $t("is") }} &gt;
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'less')"
                  >
                    {{ $t("is") }} &lt;
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-else>
                <b-input
                  v-if="head.searchable"
                  v-model="head.input"
                  :placeholder="$root.getFilterPlaceholder(head)"
                  size="is-small"
                  icon-right="filter"
                  rounded
                  icon-right-clickable
                  @icon-right-click="showDrop(head.value)"
                  @input="getUsers"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <template v-if="head.filter_type == 'numeric'">
                    <b-dropdown-item
                      v-for="filter in filtersData.numeric"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      v-for="filter in filtersData.char"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </template>
            </template>

            <template v-if="head.value == 'site_id'" v-slot="props">
              <img
                height="20"
                width="20"
                :src="'/img/sites/' + props.row.site.id + '.svg'"
              />
            </template>

            <template v-else-if="head.value == 'tld_id'" v-slot="props">
              {{ props.row.tld.label }}
            </template>

            <template v-else-if="head.value == 'id'" v-slot="props">
              <router-link :to="`/users/${props.row.id}`">
                {{ props.row.id }}
              </router-link>
            </template>

            <template v-else-if="head.value == 'company'" v-slot="props">
              {{ (props.row.company && props.row.company.name) || "-" }}
            </template>

            <template v-else-if="head.value == 'company_type'" v-slot="props">
              {{ (props.row.company && props.row.company.type) || "-" }}
            </template>

            <template v-else-if="head.value == 'invoice_email'" v-slot="props">
              {{ props.row.company.invoice.email || "-" }}
            </template>

            <template v-else-if="head.value == 'incoterm'" v-slot="props">
              {{ (props.row.company && props.row.company.incoterm) || "-" }}
            </template>

            <template v-else-if="head.value == 'factoring'" v-slot="props">
              {{ (props.row.company && props.row.company.factoring) || "-" }}
            </template>

            <template v-else-if="head.value == 'vat_num'" v-slot="props">
              {{
                (props.row.company &&
                  props.row.company.vat &&
                  props.row.company.vat.num &&
                  props.row.company.vat.num.formatted) ||
                "-"
              }}
            </template>

            <template v-else-if="head.value == 'id'" v-slot="props">
              <router-link :to="`/users/${props.row.id}`">
                {{ props.row.id }}
              </router-link>
            </template>

            <template v-else-if="head.value == 'last_order'" v-slot="props">
              {{ props.row.last_order ? props.row.last_order.formatted : "-" }}
            </template>

            <template v-else-if="head.value == 'status'" v-slot="props">
              <b-tag
                size="is-small"
                :type="
                  props.row.status.key == 'active'
                    ? 'is-success'
                    : props.row.status.key == 'inactive'
                    ? 'is-warning'
                    : 'is-danger'
                "
              >
                {{ props.row.status.label }}
              </b-tag>
            </template>

            <template v-else-if="head.value == 'salesman'" v-slot="props">
              {{
                props.row.salesman
                  ? props.row.salesman.fname + " " + props.row.salesman.lname
                  : "-"
              }}
            </template>

            <template v-else-if="head.value == 'actions'" v-slot="props">
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item aria-role="listitem" has-link>
                  <router-link :to="`/users/${props.row.id}`">
                    <b-icon icon="eye" size="is-small" />
                    {{ $t("customer_dashboard") }}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    props.row.status.key != 'removed' &&
                    props.row.status.key !== 'awaiting'
                  "
                  aria-role="listitem"
                  @click="
                    openDrawer('openUserForm', {
                      index: 0,
                      user: props.row,
                      countries: countries,
                      sites: sites,
                      salesmen: salesmen,
                    })
                  "
                >
                  <b-icon icon="pen" size="is-small" />
                  {{ $t("edit") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    $can('create', 'orders') &&
                    (props.row.status.key == 'active' ||
                      props.row.status.key == 'hbounced')
                  "
                  aria-role="listitem"
                  @click="placeOrder(props.row)"
                >
                  <b-icon icon="cart-arrow-down" size="is-small" />
                  {{ $t("place_order") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    $can('read', 'users') && props.row.status.key == 'active'
                  "
                  aria-role="listitem"
                  @click="requestPassword(props.row)"
                >
                  <b-icon icon="lock" size="is-small" />
                  {{ $t("request_password") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    $can('update', 'users') && props.row.status.key == 'active'
                  "
                  aria-role="listitem"
                  @click="suspendUser(props.row)"
                >
                  <b-icon icon="account-off" size="is-small" />
                  {{ $t("suspend") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    (props.row.status.key == 'suspended' ||
                      props.row.status.key == 'inactive' ||
                      props.row.status.key == 'hbounced') &&
                    $can('read', 'users')
                  "
                  aria-role="listitem"
                  @click="activateUser(props.row)"
                >
                  <b-icon icon="refresh" size="is-small" />
                  {{ $t("activate") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-else v-slot="props">
              {{ props.row[head.value] || `-` }}
            </template>
          </b-table-column>
        </template>

        <EmptyBlock slot="empty" icon="account-supervisor" />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
  </Page>
</template>

<script>
import Page from "@/layout/Page";
import moment from "moment";

import debounce from "debounce";

export default {
  components: {
    Page,
  },
  data() {
    return {
      searchFocused: false,
      // hasSearch is used to display empty message in table
      hasSearch: false,
      total: 0,
      loading: true,
      showColumnsTogle: false,
      list: [],
      sortingPriority: [{ field: "id", order: "asc" }],
      singleSorting: {},
      currentPage: 1,
      perPages: [50, 100, 200],
      status: [],
      perPage: 50,
      visibleCols: {
        site_id: {
          key: "site_id",
          label: this.$t("site"),
          display: true,
        },
        tld_id: {
          key: "tld_id",
          label: this.$t("tld"),
          display: false,
        },
        id: {
          key: "id",
          label: this.$t("id"),
          display: true,
        },
        fname: {
          key: "fname",
          label: this.$t("fname"),
          display: true,
        },
        lname: {
          key: "lname",
          label: this.$t("lname"),
          display: true,
        },
        email: {
          key: "email",
          label: this.$t("email"),
          display: true,
        },
        company: {
          key: "company",
          label: this.$t("company"),
          display: true,
        },
        status: {
          key: "status",
          label: this.$t("status"),
          display: true,
        },
        salesman: {
          key: "salesman",
          label: this.$tc("salesman", 1),
          display: false,
        },
        company_type: {
          key: "company_type",
          label: this.$t("type"),
          display: false,
        },
        invoice_email: {
          key: "invoice_email",
          label: this.$t("invoice_email"),
          display: false,
        },
        country: {
          key: "country",
          label: this.$t("country_company"),
          display: false,
        },
        city: {
          key: "city",
          label: this.$t("city"),
          display: false,
        },
        zip: {
          key: "zip",
          label: this.$t("zip"),
          display: false,
        },
        incoterm: {
          key: "incoterm",
          label: this.$t("incoterm"),
          display: false,
        },
        factoring: {
          key: "factoring",
          label: this.$t("factoring"),
          display: false,
        },
        vat_num: {
          key: "vat_num",
          label: this.$t("vat_num"),
          display: false,
        },
        last_order: {
          key: "last_order",
          label: this.$t("last_order"),
          display: false,
        },
        actions: {
          key: "actions",
          label: this.$t("actions"),
          display: true,
        },
      },
      headers: [
        {
          value: "site_id",
          label: this.$t("site"),
          width: "100",
          searchable: true,
          sortable: false,
          input: "",
        },
        {
          value: "tld_id",
          label: this.$t("tld"),
          width: "150",
          searchable: true,
          sortable: false,
          input: "",
        },
        {
          value: "id",
          label: this.$t("id"),
          width: "150",
          sortable: true,
          searchable: true,
          sticky: true,
          filter_type: "numeric",
          input: "",
          filter: "is",
        },
        {
          value: "fname",
          label: this.$t("fname"),
          width: "250",
          searchable: true,
          sortable: true,
          input: "",
          filter: "contains",
        },
        {
          value: "lname",
          label: this.$t("lname"),
          width: "250",
          searchable: true,
          sortable: true,
          input: "",
          filter: "contains",
        },
        {
          value: "email",
          label: this.$t("email"),
          width: "250",
          searchable: true,
          sortable: true,
          input: "",
          filter: "contains",
        },
        {
          value: "company",
          label: this.$t("company"),
          width: "250",
          sortable: true,
          searchable: true,
          input: "",
          filter: "contains",
        },
        {
          value: "status",
          label: this.$t("status"),
          width: "100",
          sortable: false,
          searchable: true,
          input: "",
          filter: "is",
        },
        {
          value: "salesman",
          label: this.$tc("salesman", 1),
          width: "100",
          searchable: true,
          sortable: true,
          input: "",
        },
        {
          value: "company_type",
          label: this.$t("type"),
          width: "250",
          sortable: true,
          searchable: true,
          input: "",
          filter_values: [],
          filter: "is",
        },
        {
          value: "invoice_email",
          label: this.$t("invoice_email"),
          width: "250",
          sortable: true,
          searchable: true,
          input: "",
          filter: "is",
        },
        {
          value: "country",
          label: this.$t("country_company"),
          width: "300",
          searchable: true,
          sortable: true,
          filter_type: "autocomplete",
          filter_text: "country",
          filter_id: "iso",
          filter_values: [],
          input: "",
          filter: "contains",
        },
        {
          value: "city",
          label: this.$t("city"),
          width: "300",
          searchable: true,
          sortable: true,
          input: "",
          filter: "contains",
        },
        {
          value: "zip",
          label: this.$t("zip"),
          width: "300",
          searchable: true,
          sortable: true,
          input: "",
          filter: "contains",
        },
        {
          value: "incoterm",
          label: this.$t("incoterm"),
          width: "200",
          searchable: true,
          sortable: true,
          filter_type: "select",
          filter_values: [],
          input: "",
          filter: "is",
        },
        {
          value: "factoring",
          label: this.$t("factoring"),
          width: "150",
          searchable: true,
          sortable: true,
          filter_type: "select",
          filter_values: [30, 45, 60],
          input: "",
          filter: "is",
        },
        {
          value: "vat_num",
          label: this.$t("vat_num"),
          width: "300",
          searchable: true,
          sortable: true,
          input: "",
          filter: "contains",
        },
        {
          value: "last_order",
          label: this.$t("last_order"),
          width: "300",
          searchable: true,
          sortable: true,
          input: null,
          filter: "is",
        },
        {
          value: "actions",
          label: this.$t("actions"),
          width: "80",
          sortable: false,
          sticky: true,
          centered: true,
        },
      ],
      countries: [],
      sites: [],
      tlds: [],
      salesmen: [],
      types: [],
    };
  },
  watch: {
    currentPage() {
      this.getUsers();
    },
    perPage() {
      this.getUsers();
    },
  },
  created() {
    this.updateTitle(this.$tc("customer", 2));
    this.getUsers();

    this.$bus.$on("update-user", (user) => {
      let index = this.list.findIndex((u) => u.id == user.id);
      this.$set(this.list, index, { ...this.list[index], ...user });
    });
    this.$bus.$on("new-user", (profile) => {
      this.list = [profile, ...this.list];
    });
  },
  destroyed() {
    this.$bus.$off("new-user");
    this.$bus.$off("update-user");
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    closeDrop() {
      this.$refs["dropdown"].toggle();
    },
    suspendUser(user) {
      this.$axios
        .patch("users/" + user.id + "/suspend")
        .then(() => {
          let suspendedStatus = this.status.find((s) => s.key == "suspended");
          user.status = suspendedStatus;
          let index = this.list.indexOf(user);
          this.$set(this.list, index, user);
          this.notify(this.$i18n.t("successfully_updated"), "is-success");
        })
        .catch((e) => this.clientError(e));
    },
    activateUser(user) {
      if (this.$can("update", "users")) {
        this.$axios
          .patch("users/" + user.id + "/activate")
          .then(() => {
            let index = this.list.indexOf(user);
            user.status = this.status.find((s) => s.key == "active");
            this.$set(this.list, index, user);
            this.notify(this.$i18n.t("successfully_updated"), "is-success");
          })
          .catch((e) => this.clientError(e));
      }
    },
    requestPassword(user) {
      if (this.$can("read", "users") && user.status.key == "active") {
        this.$axios
          .post("users/" + user.id + "/password/request")
          .then(() => {
            this.notify(
              this.$i18n.t("request_successfully_sent"),
              "is-success"
            );
          })
          .catch((e) => this.clientError(e));
      }
    },
    placeOrder(user) {
      if (this.$can("create", "orders")) {
        this.$axios
          .get("users/" + user.id + "/token")
          .then((res) => {
            let url = res.data.url;
            let token = res.data.token;
            let cid = res.data.cid;
            var win = window.open(
              url + "?atoken=" + token + "&ctoken=" + encodeURIComponent(cid),
              "_blank"
            );
            win.focus();
          })
          .catch((e) => this.clientError(e));
      }
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.sendEndpointUsers();
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = []; // [{field, order}]
        this.singleSorting = { field, order };
      }
      this.sendEndpointUsers();
    },
    sortingPriorityRemoved(field) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field
      );
      this.sendEndpointUsers();
    },
    getUsers: debounce(function (from = null) {
      if (
        from == "global-search" &&
        this.headers[5].input.length < 3 &&
        this.headers[5].input.length != 0
      ) {
        return;
      }
      this.sendEndpointUsers();
    }, 200),
    sendEndpointUsers() {
      let params = `?page=${this.currentPage}&per_page=${this.perPage}`;

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }
      let searchArr = [];
      for (let i = 0; i < this.headers.length; i++) {
        let value = this.headers[i];
        if (
          [
            "fname",
            "lname",
            "email",
            "company",
            "invoice_email",
            "city",
            "zip",
            "vat_num",
          ].includes(value.value) &&
          value.input &&
          value.input.length != 0 &&
          value.input.length < 3
        )
          return;
        else if (value.input) {
          let obj = {
            id: value.value,
            selected:
              value.value == "last_order"
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }
      if (searchArr.length)
        params += `&filters=${btoa(JSON.stringify(searchArr))}`;
      this.loading = true;
      this.$axios
        .get("users" + params)
        .then(({ data }) => {
          if (searchArr.length) this.hasSearch = true;
          else this.hasSearch = false;
          this.total = data.pagination ? data.pagination.total : 0;
          this.list = data.list;
          this.countries = data.filters.countries;
          let country_index = this.headers.findIndex(
            (h) => h.value == "country"
          );
          if (this.headers[country_index].filter_values.length == 0) {
            this.headers[country_index].filter_values = data.filters.countries;
          }
          this.types = data.filters.types;
          let company_type_index = this.headers.findIndex(
            (h) => h.value == "company_type"
          );
          if (this.headers[company_type_index].filter_values.length == 0) {
            this.headers[company_type_index].filter_values = data.filters.types;
          }

          let incoterm_index = this.headers.findIndex(
            (h) => h.value == "incoterm"
          );
          if (this.headers[incoterm_index].filter_values.length == 0) {
            this.headers[incoterm_index].filter_values =
              data.filters.incoterm || [];
          }

          this.sites = data.filters.sites;
          this.tlds = data.filters.tlds;
          this.status = data.filters.statuses;
          this.salesmen = data.filters.salesmen;
        })
        .catch((e) => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
